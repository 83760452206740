import React from "react";
import { useTranslation } from "react-i18next";

export default function About({ isChecked }) {
  const { t } = useTranslation();

  return (
    <>
      <h1
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        <div className="text-center">{t("About Us")}</div>
      </h1>
      <div className="container text-center my-5">
        <div className="row align-items-center justify-content-start">
          <div className="col-md-6">
            {!isChecked ? (
              <img
                src={require("../../Assets/images/logo1.png")}
                alt="Eye Engineer Logo"
                className="img-fluid"
              />
            ) : (
              <img
                src={require("../../Assets/images/Logo_Eye Engineer_Olive.png")}
                alt="Eye Engineer Logo"
                className="img-fluid"
              />
            )}
          </div>
          <div className="col-md-6 text-start">
            <p className="lead">
              <b>{t("AboutDescription")}</b>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
