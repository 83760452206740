import { useEffect, useState } from "react";
import { Axios } from "../../../Api/axios";
import { baseUrl } from "../../../Api/Api";
import { Form } from "react-bootstrap";
// import LoadingSubmit from "../../../Components/Loading/loading";
import PaginatedItems from "../../../Components/Pagination/Pagination";
import { NavLink, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function Terminologies() {
  const [Terminologies, setTerminologies] = useState([]);
  const [loading, setLoading] = useState("");
  const [runUseEffect, setRun] = useState(0);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  // Get All Terminologies
  // useEffect(() => {
  //   setLoading(true);
  //   Axios.get(`${baseUrl}/view-terminologies?page=${page}`)
  //     .then((data) => {
  //       setTerminologies(data.data.data.terminologies.data);
  //       setTotal(data.data.data.terminologies.total);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => setLoading(false));
  // }, [runUseEffect, page]);

  async function getSearchedData() {
    try {
      setLoading(true);
      const response = await Axios.get(
        `${baseUrl}/search-terminology?key=${search}&page=${page}`
      );

      if (response.status === 200) {
        setTerminologies(response.data.data.terminologies.data);
        setTotal(response.data.data.terminologies.total);
      } else if (response.status === 404) {
        setTerminologies([]);
        setTotal(0);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setTerminologies([]);
        setTotal(0);
      } else {
        console.log(err);
      }
    } finally {
      setSearchLoading(false);
      setLoading(false);
    }
  }
  useEffect(() => {
    const ac = localStorage.getItem("AddC");
    const uc = localStorage.getItem("UpdateC");

    if (ac === "true") {
      toast.success("Added Successfully");

      setTimeout(() => {
        localStorage.removeItem("AddC");
      }, 100);
    }

    if (uc === "true") {
      toast.success("Updated Successfully");

      setTimeout(() => {
        localStorage.removeItem("UpdateC");
      }, 100);
    }
  }, []);
  useEffect(() => {
    setLoading(true);
    const debounce = setTimeout(() => {
      search.length >= 0 ? getSearchedData() : setSearchLoading(false);
    }, 500);
    return () => clearTimeout(debounce);
  }, [search, page]);

  //   handleDelete
  async function handleDelete(id) {
    try {
      await Axios.delete(`${baseUrl}/delete-terminology/${id}`);
      setTerminologies((prev) => prev.filter((item) => item.id !== id));
      toast.success("Deleted successfully");
      setRun((prev) => prev + 1);
    } catch {
      console.log("err");
    }
  }

  const datas = Terminologies.map((type, index) => (
    <tr key={index}>
      <td>{page * 6 - 5 + index}</td>
      <td>{type.name}</td>
      <td>{type.meaning}</td>
      <td>{type.field}</td>
      <td className="aaa">
        <i
          className="fa-solid fa-pen-to-square"
          style={{ fontSize: "20px", cursor: "pointer", color: "#084B22" }}
          onClick={() => handleEdit(type)}
        />
        <i
          onClick={() => handleDelete(type.id)}
          className="fa-solid fa-trash"
          style={{
            fontSize: "20px",
            color: "#ED7200",
            cursor: "pointer",
            marginLeft: "7px",
            marginRight: "7px",
          }}
        />
        <i
          className="fa-solid fa-arrow-right"
          title="categories"
          style={{ fontSize: "20px", cursor: "pointer", color: "#111827" }}
          onClick={() => view_Categories(type)}
        />
      </td>
    </tr>
  ));
  const handleEdit = (ter) => {
    navigate(`update_terminology/${ter.id}`, { state: { ter } });
  };
  const view_Categories = (ter) => {
    navigate(`view_Categories`, { state: { ter } });
  };

  return (
    <>
      {/* {loading && <LoadingSubmit />} */}
      <ToastContainer />
      <div className="table-container">
        <h1 className="text-center tt">{i18n.t("Terminologies")}</h1>
        <div className="col-3 scm">
          <Form.Control
            type="search"
            aria-label="input example"
            placeholder={i18n.t("search")}
            className="my-2"
            onChange={(e) => {
              setSearch(e.target.value);
              setSearchLoading(true);
            }}
          />
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th> {i18n.t("Name")}</th>
              <th>{i18n.t("Meaning")}</th>
              <th>{i18n.t("Field")}</th>
              <th className="aaa">
                <NavLink
                  className="fa-solid fa-plus btn btnct"
                  to="add_terminology"
                ></NavLink>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="text-center">
                <td colSpan={12}>
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : searchLoading ? (
              <tr className="text-center">
                <td colSpan={12}>
                  <div class="spinner-border text-success" role="status">
                    <span class="visually-hidden">search...</span>
                  </div>
                </td>
              </tr>
            ) : datas.length === 0 ? (
              <td colSpan={12}>
                <div className="text-center">
                  <span>{i18n.t("Recents are not found")}</span>
                </div>
              </td>
            ) : (
              datas
            )}
          </tbody>
        </table>
        <div style={{ display: "flex", justifyContent: "center", margin: "0" }}>
          {total > 6 ? (
            <PaginatedItems
              setPage={setPage}
              data={Terminologies}
              total={total}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
