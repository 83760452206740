import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PolicyComponent.css";
const EnglishPolicy = () => {
  return (
    <>
      <h1
        className="text-center"
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        Privacy Policy
      </h1>
      <div className="container mt-4">
        <div className="mb-4">
          <h4 className="text-orange">Introduction:</h4>
          <p>
            Eye Engineer website recognizes the importance of protecting your
            personal information.
          </p>
          <p>
            We have prepared this privacy policy to provide you with important
            information about the privacy practices applied to the Eye Engineer
            platform and any website or service linked to or referred to by this
            privacy policy (collectively referred to as "the Services").
          </p>
          <p>
            Eye Engineer operates by assisting you in finding reliable and
            engineering-related information and connecting you with the best
            engineers to share information with.
          </p>
          <p>
            Eye Engineer works by linking you with engineers as quickly as
            possible when you need information (in the appropriate manner for
            virtual consultations) directly from your computer or mobile phone.
          </p>
          <p>
            By continuing to use Eye Engineer website, you agree to this privacy
            policy and any updates made to it.
          </p>
          <p>
            This means that if changes are made to the privacy policy, you agree
            to those changes by continuing to use Engineer's Eye.
          </p>
          <p>
            When changes are made to this policy (other than changes made to
            correct typographical errors or minor changes that do not alter its
            meaning), we will update the "Last Modified" date at the top of the
            policy.
          </p>
          <p>
            In the bottom part of the policy, we may provide a summary
            describing the types of changes that have been made.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Terms and Conditions of Website Use:</h4>
          <p>
            By using the website, you agree not to use any device, software, or
            routine to interfere or attempt to interfere with the proper
            functioning of the website or any transaction conducted on the
            website, or with any use of the website by another person.
          </p>
          <p>
            You are not allowed to manipulate addresses or identifiers to
            conceal the origin of any message.
          </p>
          <p>
            Impersonation of any individual or entity is strictly prohibited.
          </p>
          <p>
            You may not use the website or any content for any unlawful or
            prohibited purpose under these terms of use.
          </p>
          <p>
            It is your responsibility to keep your account and passwords secure.
          </p>
          <p>
            Any breach of your account may lead to issues for another user or
            potentially for our website and services.
          </p>
          <p>
            Therefore, you are entirely responsible for the security of your
            account on the website.
          </p>
          <p>
            You should be aware that any messages or transactions you perform
            might be visible to others with the intention of exploitation.
          </p>
          <p>
            The website cannot guarantee that any files or data you download
            from the website will be free from viruses, contaminants, or
            supporting features.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Collecting Information:</h4>
          <p>
            When you use our services, we may collect the following types of
            information:
          </p>

          <h5>Information You Provide Directly:</h5>
          <p>
            For certain activities, such as when you register, subscribe to our
            notifications, or directly communicate with us; we may collect some
            or all of the following types of information:
          </p>
          <p>
            <strong>Registration:</strong> Contact information, such as your
            name, email address, date of birth, and mobile phone number.
          </p>
          <p>
            <strong>Payment Information:</strong> When using paid services on
            Eye Engineer, we require payment information.
          </p>
          <p>
            If you make a payment using a credit card or local debit card, we
            will collect specific information such as your credit card or debit
            card number, expiration date, and security code.
          </p>
          <p>
            Additionally, the service provider (a third party assisting or
            representing us in facilitating our services) managing our payment
            operations will obtain information from your credit card or debit
            card.
          </p>
          <p>
            Their use of this information will be governed by their own privacy
            policy.
          </p>
          <p>
            <strong>Engineering Information Providers:</strong> We will collect
            specific information about your work, such as job title, field of
            practice, primary specialization, gender, date of birth, languages
            spoken, address, image, professional license information, and
            banking account information.
          </p>
          <p>
            <strong>Other Information:</strong> Additionally, we will collect
            any other information you provide to us.
          </p>
          <p>
            It is important to note that any payment information collected is
            processed in accordance with the privacy policy of the payment
            service provider, a third party involved in handling our payment
            operations.
          </p>
          <h5>Automatically Collected Information:</h5>
          <p>
            When you use our services, we may automatically collect certain
            types of information, including but not limited to:
          </p>
          <p>
            <strong>IP Address and Device Identifiers:</strong> Information such
            as IP address, device identifiers, advertising identifiers, browser
            type, operating system, internet service provider, pages visited
            before and after using the services, date and time of visit, and
            standard server log information.
          </p>
          <p>
            <strong>Cookies, Pixel Tags, and Local Shared Objects:</strong> We
            may use cookies, pixel tags, and local shared objects (also known as
            "Flash cookies") to automatically collect information.
          </p>
          <p>
            Cookies are small pieces of information stored by your web browser
            on your computer.
          </p>
          <p>
            Pixel tags are tiny images or small parts of data embedded in
            images, also known as "web beacons" or "clear GIFs," which can
            recognize cookies and the time and date a page was viewed, as well
            as describe the page where the pixel tag is placed.
          </p>
          <p>
            Local shared objects are similar to standard cookies but may be
            larger in size and are downloaded to your computer or mobile device
            by the Adobe Flash player.
          </p>
          <p>
            By using our services, you agree to our use of cookies, pixel tags,
            local shared objects, and similar technologies.
          </p>
          <p>
            <strong>Local Shared Objects (Flash Cookies):</strong> Local shared
            objects, sometimes referred to as "Flash cookies," are similar to
            standard cookies except that they may be larger in size and are
            downloaded to your computer or mobile device by the Adobe Flash
            player.
          </p>
          <p>
            By using our services, you agree to our use of cookies, pixel tags,
            local shared objects, and similar technologies.
          </p>
          <p>
            <strong>Technical Data for Troubleshooting:</strong> We may also
            collect technical data for processing and troubleshooting technical
            issues and improving our services.
          </p>
          <p>
            This may include information about the state of your device's memory
            in the event of a system or application failure while using our
            services.
          </p>
          <p>
            Your device or browser settings may allow you to control the
            collection of this technical data.
          </p>
          <p>
            This data may include portions of a document you were using when a
            problem occurred or the contents of your communications.
          </p>
          <p>
            By using the services, you agree to the collection of this technical
            data.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Use of Information:</h4>
          <p>
            In general, we use the information we collect online for the
            following purposes:
          </p>
          <p>
            <strong>Operating and Providing Services:</strong> Operating,
            providing, maintaining, improving, and enhancing our services.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Retention of Information:</h4>
          <p>
            We will retain your information for as long as necessary to fulfill
            the purposes for which it was collected, as outlined in this privacy
            policy, unless a longer retention period is required or permitted by
            law.
          </p>
          <p>
            The criteria used to determine our retention periods include the
            duration of your relationship with us, the nature of the data
            collected, and our legal obligations or operational needs.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Security Measures:</h4>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, use, or disclosure.
          </p>
          <p>
            However, no data transmission over the internet or storage system
            can be guaranteed to be 100% secure.
          </p>
          <p>
            Therefore, we cannot guarantee the absolute security of your
            information.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Children's Privacy:</h4>
          <p>
            Our services are not intended for use by individuals under the age
            of 18.
          </p>
          <p>
            We do not knowingly collect personal information from individuals
            under the age of 18.
          </p>
          <p>
            If we become aware that we have collected personal information from
            a child under the age of 18 without parental consent, we will take
            steps to delete the information as soon as possible.
          </p>
          <p>
            If you believe that we may have collected personal information from
            a child under the age of 18, please contact us using the contact
            information provided below.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Changes to the Privacy Policy:</h4>
          <p>
            We may update this privacy policy from time to time to reflect
            changes in our practices or legal obligations.
          </p>
          <p>
            We encourage you to review this policy periodically for any updates.
          </p>
          <p>
            The "Last Modified" date at the top of this policy indicates when it
            was last revised.
          </p>
          <p>
            If we make any material changes to this policy, we will provide
            notice through our services or by other means as required by
            applicable law.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Contact Us:</h4>
          <p>
            If you have any questions, concerns, or requests regarding this
            privacy policy or our privacy practices, please contact us at
            privacy@eyeengineer.com.
          </p>
          <p>
            This summary provides an overview of the key points of the Eye
            Engineer privacy policy.
          </p>
          <p>
            It does not replace the full policy, which you should read for a
            complete understanding of how your information is collected, used,
            and protected.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Protection:</h4>
          <p>
            We implement reasonable measures to help protect information from
            loss, theft, misuse, unauthorized access, disclosure, alteration,
            and destruction.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Your Choices:</h4>
          <p>
            You can request that we delete your personal information by sending
            an email to info@eyeengineer.com.
          </p>
          <p>
            You can also refuse or disable cookies by adjusting your web browser
            settings.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">
            Terms of Membership for Adding Your Electronic Office and Opening an
            Account:
          </h4>
          <p>
            You must be 25 years old or older according to laws and regulations.
          </p>
          <p>
            The office's activities must be within the engineering fields
            allowed to be practiced exclusively.
          </p>
          <p>Otherwise, the application will be rejected.</p>
          <p>
            You must register with Engineering Eye to use and benefit from the
            service by contacting the Engineering Eye team via email at
            info@eyeengineer.com.
          </p>
          <p>
            Engineering Eye has the right to reject any request to create an
            account or add your electronic office.
          </p>
          <p>
            Engineering Eye also has the right to terminate the Add Your
            Electronic Office service or any account for any violation within
            the site's policy, without prior notice and at any time.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">
            Advertising and Content from External Parties:
          </h4>
          <p>
            Some services may contain links to content stored by external
            parties not under our control.
          </p>
          <p>
            We allow third parties, including business partners, advertising
            networks, and other advertising and promotion service providers, to
            collect information about your online activities through cookies,
            pixel tags, local storage, and other technologies.
          </p>
          <p>
            These third parties may use this information to display ads on our
            services and elsewhere online tailored to your interests,
            preferences, and characteristics.
          </p>
          <p>
            We are not responsible for the privacy practices of these external
            parties, and the information practices of such parties are not
            covered by this Privacy Policy.
          </p>
          <p>
            Some external parties collect information about users of our
            services to provide interest-based advertising displayed on our
            services and other places, including across browsers and devices.
          </p>
          <p>
            These external parties may use the information collected on our
            services to make predictions about your interests for the purpose of
            providing you with (from us or other companies) online ads.
          </p>
          <p>
            Some external parties participate in an industry system that allows
            users to opt-out of receiving ads designed based on their online
            activities.
          </p>
          <p>
            Due to differences in the use of applications and websites on mobile
            devices, you may need to take additional steps to disable targeted
            advertising techniques in mobile applications.
          </p>
          <p>
            Many mobile devices allow you to opt-out of targeted advertising
            from mobile applications using the settings included in the mobile
            application or your mobile device.
          </p>
          <p>For more information, please check your mobile device settings.</p>
          <p>
            You can also uninstall our applications using the standard uninstall
            process available on your mobile device or in the app store.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Management Rights:</h4>
          <p>The site's management reserves the right to:</p>
          <p>
            Modify, suspend, or terminate the operation or access to the site or
            any part thereof for any reason.
          </p>
          <p>
            Modify or change the site, or any part thereof, and any current
            policies or terms.
          </p>
          <p>
            Suspend the operation of the site, or any part thereof, as necessary
            for routine or non-routine maintenance.
          </p>
          <p>
            Disclose any information believed to be necessary to comply with any
            applicable law, regulation, legal process, or government request.
          </p>
          <p>
            The site reserves the right to terminate your access to the site
            and/or block your future access to the site if it determines that
            you have violated these terms of use, agreements, or other
            guidelines.
          </p>
          <p>
            If the site takes any legal action against you as a result of your
            violation of these terms of use, it shall be entitled to recover
            from you, and you agree to pay, all reasonable attorneys' fees and
            costs of such action.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Links to Other Websites:</h4>
          <p>
            The site may contain links to other websites not operated by us.
          </p>
          <p>
            We advise you to review the privacy policy for each website you
            visit.
          </p>
          <p>
            We do not control and are not responsible for the content, privacy
            policies, or practices of third-party websites and their services.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Modifications to the Privacy Policy:</h4>
          <p>We may update this privacy policy from time to time.</p>
          <p>
            When we update the privacy policy, we will modify the "date of
            access" mentioned above and publish the new privacy policy.
          </p>
          <p>
            The updated policy will be effective from the date of publication on
            the site.
          </p>
          <p>
            We recommend reviewing the privacy policy each time you visit the
            services to stay informed about our privacy practices.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">Questions?</h4>
          <p>
            If you have any questions about this privacy policy or our
            practices, please send an email to info@eyeengineer.com.
          </p>
        </div>
      </div>
    </>
  );
};

export default EnglishPolicy;
