import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      English:'English',
      Arabic:'Arabic',
      Home: "Home",
      "About Us": "About Us",
      Capabilities: "Capabilities",
      "Contact Us": "Contact Us",
      "Sign Up": "Sign Up",
      Login: "Login",
      Geometries: "Geometries",
      GeometriesDescription:
        "Contains diverse engineering information and resources",
      "Educational Videos": "Educational Videos",
      EducationalVideosDescription:
        "Provides a collection of instructional videos",
      "Engineering Articles": "Engineering Articles",
      EngineeringArticlesDescription: "Contains diverse articles",
      Recents: "Recents",
      RecentsDescription: "Provides the latest information and news",
      Terminologies: "Terminologies",
      TerminologiesDescription:
        "Provides definitions of technical and professional terms",
      Offices: "Offices",
      OfficesDescription:
        "Offers tips and ideas to enhance the work environment in offices",
      View: "View",
      FooterDescription:
        "Explore engineering and related information, Files and resources for various engineering disciplines.",
      CreatedBy: "Created By",
      Copyright: "&copy; 2022 - ",
      "Useful Links": "Useful Links",
      "Reserve Office": "Reserve Office",
      "Intelligent Translator": "Intelligent Translator",
      "Privacy Policy": "Privacy Policy",
      "Articles Policy": "Articles Policy",
      "Questions Policy": "Questions Policy",
      "Our Services": "Our Services",
      "Property Valuation": "Property Valuation",
      ContactDescription:
        "Get in touch with us via mail phone.We are waiting for your call or message",
        Email_text: "info@eyeengineer.com",
      AboutDescription:
        "Engineer's Eye is a specialized platform dedicated to providing remote engineering consulting services and delivers reliable engineering information to those in need. If you have interest and desire to discuss any engineering topic, we encourage you to reach out to us. Knowledge and information are not exclusive to anyone. Share information and benefits for everyone.",
      "Get In Touch": "Get In Touch",
      "Opening Hours": "Opening Hours",
      Whatsapp: "Whatsapp",
      Address: "Address",
      subject: "Subject",
      content: "Content",
      "Send Message": "Send Message",
      "09:00am to 06:00pm": "09:00am to 06:00pm",
      Syria: "Syria",
      Services: "Our Popular Services",
      OurCapabilities: "Our Capabilities",
      "Email is required": "Email is required",
      "Password is required": "Password is required",
      "Sign in": "Sign in",
      Email: "Email",
      Password: "Password",
      "Forgot password?": "Forgot password?",
      "Don't have an account?": "Don't have an account?",
      Register: "Register",
      Logout: "Logout",
      "Create an Account": "Create an Account",
      "User Name": "User Name",
      "Confirm Password": "Confirm Password",
      SignUp: "Sign Up",
      "User Name is required": "User Name is required",
      "Confirm Password is required": "Confirm Password is required",
      "Passwords do not match": "Passwords do not match",
      "The email has already been taken.": "The email has already been taken.",
      valuation_title:'Property valuation',
      valuationDescription:'Provides property valuations by experts',
      valuation_d:'Take an evaluation from the best experts',
      contact_phone:'contact_phone',
      EngineeringArticles:'Engineering Articles',
      'Message sent successfully!':'Message sent successfully',
      text:'text',
       'Select Source Language':'Select Source Language',
      'Select Target Language':'Select Target Language',
      translate:'translate',
      "Overview": "Overview",
      "An article at Eye Engineer is a piece of writing on a specific engineering topic. It is required to adhere to the following points:": "An article at Eye Engineer is a piece of writing on a specific engineering topic. It is required to adhere to the following points:",
      "The article must be exclusively focused on engineering topics (no unrelated articles are allowed).": "The article must be exclusively focused on engineering topics (no unrelated articles are allowed).",
      "Maintain a formal writing style and avoid informal conversational language.": "Maintain a formal writing style and avoid informal conversational language.",
      "Keep the article simple and understandable, with an emphasis on explaining engineering terms.": "Keep the article simple and understandable, with an emphasis on explaining engineering terms.",
      "The article should be aimed at scientific benefit based on reliable engineering facts.": "The article should be aimed at scientific benefit based on reliable engineering facts.",
      "Provide names of used references, their publication date, the publishing house's name, and page numbers.": "Provide names of used references, their publication date, the publishing house's name, and page numbers.",
      "Also, mention the names of the websites quoted, along with the date of quoting.": "Also, mention the names of the websites quoted, along with the date of quoting.",
      "The author of the article is responsible for the accuracy of this information both scientifically and legally.": "The author of the article is responsible for the accuracy of this information both scientifically and legally.",
      "The article should have a well-structured format in terms of font, sentence structure, and overall readability.": "The article should have a well-structured format in terms of font, sentence structure, and overall readability.",
      "Choose an appropriate title that reflects the content of the article.": "Choose an appropriate title that reflects the content of the article.",
      "Avoid including any article containing unwanted emails or harmful programs.": "Avoid including any article containing unwanted emails or harmful programs.",
      "Ensure compliance with all copyright and other relevant laws.": "Ensure compliance with all copyright and other relevant laws.",
      "Respect copyright laws, as our policy involves responding to explicit notifications claiming copyright violations, leading to the immediate removal of the article.": "Respect copyright laws, as our policy involves responding to explicit notifications claiming copyright violations, leading to the immediate removal of the article.",
      "Avoid publishing personal information that should not be generally accessible.": "Avoid publishing personal information that should not be generally accessible.",
      "Engineers who wish to contribute to Eye Engineer are requested to send their requests to the Eye Engineer team at info@eyeengineer.com.": "Engineers who wish to contribute to Eye Engineer are requested to send their requests to the Eye Engineer team at info@eyeengineer.com.",
      "QuestionsPolicy": "Questions Policy",
      "Question Conditions": "Question Conditions",
      "The question must be scientific and engineering only (no response will be provided if the question is outside the scope of engineering).": "The question must be scientific and engineering only (no response will be provided if the question is outside the scope of engineering).",
      "The question should be concise and not a text.": "The question should be concise and not a text.",
      "Only one question at a time.": "Only one question at a time.",
      "Avoid personal details and refrain from using personal terms.": "Avoid personal details and refrain from using personal terms.",
      "Inappropriate words or any content unrelated to engineering are not allowed.": "Inappropriate words or any content unrelated to engineering are not allowed.",
      "To avoid redundant questions, it is required to search for the topic on the website before asking the question.": "To avoid redundant questions, it is required to search for the topic on the website before asking the question.",
      "Answer Policy on Eye Engineer Website": "Answer Policy on Eye Engineer Website",
      "Answers should be written in proper Arabic, clear and understandable to Arab readers; English can be used in the answer.": "Answers should be written in proper Arabic, clear and understandable to Arab readers; English can be used in the answer.",
      "The answer should be comprehensive and explanatory for each point in the question.": "The answer should be comprehensive and explanatory for each point in the question.",
      "The answer must be based on scientific engineering principles only and should not reflect personal opinions or be quoted from forums.": "The answer must be based on scientific engineering principles only and should not reflect personal opinions or be quoted from forums.",
      "Avoid any form of advertising or promotion for a specific company.": "Avoid any form of advertising or promotion for a specific company.",
      "Stay away from texts unrelated to the engineering field.": "Stay away from texts unrelated to the engineering field.",
      "Avoid any form of personal promotion during the answer.": "Avoid any form of personal promotion during the answer.",
      "Engineer's response is considered indirect promotion, and contact information can be placed in their profile, not in the answer.": "Engineer's response is considered indirect promotion, and contact information can be placed in their profile, not in the answer.",
      "This should not encourage the questioner to contact or visit.": "This should not encourage the questioner to contact or visit.",
      "Avoid mentioning the name of the questioner or any personal information; the Eye Engineer website respects the privacy of individuals.": "Avoid mentioning the name of the questioner or any personal information; the Eye Engineer website respects the privacy of individuals.",
      "Respect for Other Engineers Opinions": "Respect for Other Engineers' Opinions",
      "Respond to the posed question and attempt to express one's point of view based on scientific engineering principles, avoiding personal biases or unrelated matters.": "Respond to the posed question and attempt to express one's point of view based on scientific engineering principles, avoiding personal biases or unrelated matters.",
      "Note: Any violation of these points will result in the immediate deletion of the answer without prior notification to the engineer.": "Note: Any violation of these points will result in the immediate deletion of the answer without prior notification to the engineer.",
       "PrivacyPolicy": "Privacy Policy",
  "Introduction": "Introduction",
  "IntroductionText1": "Eye Engineer website recognizes the importance of protecting your personal information.",
  "IntroductionText2": "We have prepared this privacy policy to provide you with important information about the privacy practices applied to the Eye Engineer platform and any website or service linked to or referred to by this privacy policy (collectively referred to as \"the Services\").",
  "IntroductionText3": "Eye Engineer operates by assisting you in finding reliable and engineering-related information and connecting you with the best engineers to share information with.",
  "IntroductionText4": "In addition to the above, this privacy policy aims to give you detailed information about the ways we use and protect the personal data we collect from users of the Services.",
  "IntroductionText5": "We may update this privacy policy from time to time, and the latest version will be posted on the Services.",
  "IntroductionText6": "Your continued use of the Services after any changes to this privacy policy will indicate your acceptance of the updated policy.",
  "IntroductionText7": "If you have any questions about this privacy policy, please contact us at the contact details provided below.",
  "TermsAndConditions": "Terms and Conditions",
  "TermsAndConditionsText1": "The terms and conditions of this privacy policy include the use of the Services and any other policies that govern your use of the Services.",
  "TermsAndConditionsText2": "By using the Services, you agree to comply with the terms and conditions outlined in this privacy policy.",
  "TermsAndConditionsText3": "If you do not agree with any of the terms and conditions, you should discontinue using the Services.",
  "TermsAndConditionsText4": "Any modifications to this privacy policy will be communicated through the Services.",
  "TermsAndConditionsText5": "Your continued use of the Services after any changes will constitute acceptance of those changes.",
  "TermsAndConditionsText6": "For more details on the terms and conditions, please refer to the full policy available on the Services.",
  "TermsAndConditionsText7": "We may revise these terms and conditions from time to time, and such changes will be effective immediately upon posting on the Services.",
  "CollectingInformation": "Collecting Information",
  "CollectingInformationText1": "We collect various types of information in order to provide and improve our Services.",
  "InformationYouProvideDirectly": "Information You Provide Directly",
  "Registration": "When you register on the Services, you may provide us with personal details such as your name, email address, and contact information.",
  "PaymentInformation": "If you make any purchases through the Services, you may need to provide payment information including credit card details.",
  "EngineeringInformationProviders": "For those using engineering services, additional information such as your engineering needs and project details may be collected.",
  "OtherInformation": "We may also collect other information you voluntarily provide through surveys, feedback forms, or other interactions.",
  "PaymentInfoProcessing": "Payment information is processed securely through a third-party payment gateway, and we do not store credit card details.",
  "AutomaticallyCollectedInformation": "Automatically Collected Information",
  "IPAddressAndDeviceIdentifiers": "We may collect information about your device, including IP address, device identifiers, and browser type.",
  "CookiesPixelTags": "We use cookies and pixel tags to collect data on your usage of the Services and to provide a better user experience.",
  "LocalSharedObjects": "Local shared objects (such as Flash cookies) may also be used to enhance your experience on the Services.",
  "TechnicalData": "Technical data such as your device's operating system and your interactions with the Services may also be collected.",
  "UseOfInformation": "Use of Information",
  "OperatingAndProvidingServices": "We use the collected information to operate and provide the Services, including processing transactions, improving our offerings, and communicating with you.",
  "RetentionOfInformation": "Retention of Information",
  "RetentionInfo": "We retain personal information for as long as necessary to fulfill the purposes for which it was collected, or as required by law.",
  "SecurityMeasures": "Security Measures",
  "SecurityInfo": "We implement security measures to protect your personal information from unauthorized access, disclosure, or destruction.",
  "ChildrensPrivacy": "Children's Privacy",
  "ChildrensPrivacyText1": "The Services are not intended for children under the age of 13.",
  "ChildrensPrivacyText2": "We do not knowingly collect personal information from children under 13.",
  "ChildrensPrivacyText3": "If we learn that we have collected personal information from a child under 13, we will take steps to delete such information.",
  "ChildrensPrivacyText4": "Parents or guardians can contact us to review or request the deletion of personal information about their children.",
  "ChangesToPrivacyPolicy": "Changes to Privacy Policy",
  "PolicyUpdates": "We may update this privacy policy from time to time to reflect changes in our practices or legal requirements.",
  "ReviewPolicy": "We encourage you to review this privacy policy periodically for any changes.",
  "LastModifiedDate": "The date of the last modification will be noted at the end of this privacy policy.",
  "MaterialChangesNotice": "Material changes to this privacy policy will be communicated through the Services.",
  "ContactUs": "Contact Us",
  "ContactUsText1": "If you have any questions or concerns about this privacy policy, please contact us using the details provided below.",
  "ContactUsText2": "We will respond to your inquiries as soon as possible.",
  "Protection": "Protection",
  "ProtectionInfo": "We strive to ensure the protection and confidentiality of your personal information and take appropriate measures to safeguard it.",
  "YourChoices": "Your Choices",
  "DeletePersonalInfo": "You may have the option to delete or update your personal information through your account settings.",
  "DisableCookies": "You can also disable cookies through your browser settings, although this may affect your experience with the Services.",
  "MembershipTerms": "Membership Terms",
  "MembershipAgeRequirement": "Membership to the Services is subject to age requirements and other eligibility criteria.",
  "OfficeActivityRequirement": "Members are expected to engage in activities related to their profession and comply with our policies.",
  "ApplicationRejection": "We reserve the right to reject or terminate membership applications at our discretion.",
  "RegistrationRequirement": "Registration is required to access certain features of the Services.",
  "RightToReject": "We reserve the right to reject or suspend membership for any reason.",
  "TerminationRights": "We may terminate membership if you fail to comply with our terms and conditions.",
  "AdvertisingContent": "Advertising Content",
  "ExternalContentLinks": "The Services may contain links to external websites and third-party content.",
  "ThirdPartyInfoCollection": "We are not responsible for the privacy practices or information collection of third-party websites.",
  "InterestBasedAds": "We may use interest-based advertising to deliver relevant ads to you based on your preferences and behavior.",
  "PrivacyPracticesOfOthers": "Please review the privacy practices of third-party sites and services linked to from the Services.",
  "OptOutOptions": "You may have options to opt out of certain advertising practices through your browser settings or third-party tools.",
  "ManagementRights": "Management Rights",
  "RightToModify": "We reserve the right to modify the Services and their content at any time.",
  "RightToChange": "We may change the terms of this privacy policy, and such changes will be effective immediately upon posting.",
  "MaintenanceRights": "We reserve the right to maintain and update the Services as needed.",
  "LegalDisclosure": "We may disclose information if required by law or in response to legal processes.",
  "TerminateAccess": "We may terminate access to the Services if you violate our terms and conditions.",
  "LinksToOtherWebsites": "Links to Other Websites",
  "LinksToOtherSites": "The Services may contain links to other websites not operated by us.",
  "ReviewPrivacyPolicies": "We encourage you to review the privacy policies of those websites to understand their practices.",
  "PrivacyPolicyModifications": "Privacy Policy Modifications",
  "DateOfAccessModification": "Any changes to this privacy policy will be effective as of the date of access modification.",
  TerminologiesC:'Categries',
  Classifications:'Classifications',
  Articles:'Articles',
  Office_Reservation:'Office Reservation',
  dr:'Reserve a space at our website to showcase office information and files. Our website provides customers with the ability to book a spot specifically for displaying office details and related documents. Whether you want to showcase office specifications, images, or other relevant information, our reservation system allows you to secure a designated area for this purpose. Reserve your space now to effectively present your office and its associated materials.',
  office_name:'office name',
  office_description:'office description',
  checkcode:'Check your email and enter the code',
  Accept:'Accept',
  Code:'Code',
  'code is required':'code is required',
  'The code is wrong':'The code is wrong',
  profile:'profile',
  Dashboard:'Dashboard',
  'Enter your email':'Enter your email',
  'The Email not found':'Account is not found',
  passwordUpdate:'The password has been updated',
  golo:'Go to log in again',
  Delete:'Delete',
  'Logged out successfully':'Logged out successfully',
  'Account deleted successfully':'Account deleted successfully',
  'Account successfully created':'Account successfully created',
  'logged in successfully':'logged in successfully',
  'Profile modified successfully':'Profile modified successfully',
  'Edit Profile':'Edit Profile',
  Edit:'Edit',
  VIDEOS:'VIDEOS',
  AUDIOS:'AUDIOS',
  IMAGES:'IMAGES',
  PDF:'PDF',
  ZIP:'ZIP',
  'Videos are not found':'Videos are not found',
  'Recents are not found':'Data are not found',
  'Geometries are not found':'Geometries are not found',
  'Offices are not found':'Offices are not found',
  'Terminologies are not found':'Terminologies are not found',
  'Categories are not found':'Categories are not found',
  'Classifications are not found':'Classifications are not found',
  'Links are not found':'Links are not found',
  'Articles are not found':'Articles are not found',
  'Reserve':'Reserve',
  'The office is blocked':'The office is blocked',
  files:'files',
  links:'links',
  articles:'articles',
  Users:'Users',
  Slides:'Slides',
  News:'News',
  Reservations:'Reservations',
  Messages:'Messages',
  'My Offices':'My Offices',
  Forbidden:'Forbidden',
  search:'search',
  Name:'Name',
  Image:'Image',
  Phone:'Phone',
  Experiences:'Experiences',
  Title:'Title',
  Url:'Url',
  Text:'Text',
  Type:'Type',
  'Add file':'Add File',
  'Update file':'Update File',
  'Save':'Save',
  'Upload file':'Upload file',
  Role:'Role',
  Admin:'Admin',
  User:'User',
  'Choose A Role':'Choose A Role',
  'Update User':'Update User',
  'Add Slider':'Add Slider',
  'Update Slider':'Update Slider',
  New:'New',
  'Add New':'Add New',
  'Update New':'Update New',
  Service:'Service',
  'show able':'show able',
  Subject:'Subject',
  Content:'Content',
  Description:'Description',
  Priority:'Priority',
  'Add Geometry':'Add Geometry',
  'Update Geometry':'Update Geometry',
  Geometry:'Geometry',
  'Add Classification':'Add Classification',
  'Update Classification':'Update Classification',
  Video:'Video',
  'Upload Video':'Upload Video',
  'Add Video':'Add Video',
  'Update Video':'Update Video',
  'Add Recent':'Add Recent',
  'Update Recent':'Update Recent',
  Meaning:'Meaning',
  Field:'Field',
  'Terminologies Categories':'Terminologies Categories',
  Terminologie:'Terminologie',
  'Add Terminology':'Add Terminology',
  'Add Category':'Add Category',
   'Update Terminology':'Update Terminology',
   'Update Category':'Update Category',
   'Update Office':'Update Office',
   'Add Office':'Add Office',
   Add:'Add',
   Remove:'Remove',
   'Enter service':'Enter service',
   'Enter experience':'Enter experience',
   'Update Link':'Update Link',
   'Add Link':'Add Link',
   'Update Article':'Update Article',
   'Add Article':'Add Article',


  

  


    },
  },
  ar: {
    translation: {
      English:'الانكليزية',
      Arabic:'العربية',
      Home: "الصفحة الرئيسية",
      "About Us": "معلومات عنا",
      Capabilities: "القدرات",
      "Contact Us": "اتصل بنا",
      "Sign Up": "انشاء حساب",
      Login: "تسجيل الدخول",
      Geometries: "الهندسيات",
      GeometriesDescription: "يحتوي على معلومات وموارد هندسية متنوعة",
      "Educational Videos": "فيديوهات تعليمية",
      EducationalVideosDescription: "يوفر مجموعة من الفيديوهات التعليمية",
      "Engineering Articles": "مقالات هندسية",
      EngineeringArticlesDescription: "يحتوي على مقالات متنوعة",
      Recents: "الأحدث",
      RecentsDescription: "يوفر أحدث المعلومات والأخبار",
      Terminologies: "المصطلحات",
      TerminologiesDescription: "يوفر تعريفات للمصطلحات الفنية والمهنية",
      Offices: "المكاتب",
      OfficesDescription: "يقدم نصائح وأفكار لتحسين بيئة العمل في المكاتب",
      View: "عرض",
      FooterDescription:
        "استكشاف المعلومات الهندسية والملفات والموارد المتعلقة بمختلف التخصصات الهندسية.",
      CreatedBy: "تم الإنشاء بواسطة",
      Copyright: "&copy; 2022 - ",
      "Useful Links": "روابط مفيدة",
      "Reserve Office": "حجز مكتب",
      "Intelligent Translator": "المترجم الذكي",
      "Privacy Policy": "سياسة الخصوصية",
      "Articles Policy": "سياسة المقالات",
      "Questions Policy": "سياسة الأسئلة",
      "Our Services": "خدماتنا",
      "Property Valuation": "تقييم العقارات",
      ContactDescription:
        "تواصل معنا عبر البريد الإلكتروني أو الهاتف. نحن في انتظار مكالمتك أو رسالتك",
      Email_text: "info@eyeengineer.com",
      AboutDescription:
        "عين المهندس هي منصة متخصصة في تقديم خدمات الاستشارات الهندسية عن بعد وتوفير معلومات هندسية موثوقة لمن يحتاجونها. إذا كنت مهتمًا ولديك رغبة في مناقشة أي موضوع هندسي، نشجعك على التواصل معنا. المعرفة والمعلومات ليست حصرية لأحد. شارك المعلومات والفوائد للجميع.",
      "Get In Touch": "تواصل معنا",
      "Opening Hours": "ساعات العمل",
      Whatsapp: "واتساب",
      Address: "العنوان",
      subject: "الموضوع",
      content: "المحتوى",
      "Send Message": "إرسال الرسالة",
      "09:00am to 06:00pm": "09:00 صباحاً إلى 06:00 مساءً",
      Syria: "سوريا",
      Services: "خدماتنا",
      OurCapabilities: "قدراتنا",
      "Email is required": "البريد الإلكتروني مطلوب",
      "Password is required": "كلمة المرور مطلوبة",
      "Sign in": "تسجيل الدخول",
      Email: "البريد الإلكتروني",
      Password: "كلمة المرور",
      "Forgot password?": "هل نسيت كلمة المرور؟",
      "Don't have an account?": "ليس لديك حساب؟",
      Register: "سجل",
      Logout: "الخروج",
      "Create an Account": "إنشاء حساب",
      "User Name": "اسم المستخدم",
      "Confirm Password": "تأكيد كلمة المرور",
      "SignUp": "تسجيل",
      "User Name is required": "اسم المستخدم مطلوب",
      "Confirm Password is required": "تأكيد كلمة المرور مطلوب",
      "Passwords do not match": "كلمات المرور غير متطابقة",
      "The email has already been taken.": "البريد الإلكتروني مستخدم بالفعل",
      valuation_title:'التقييم العقاري',
      valuationDescription:'يوفر تقييمات للممتلكات من قبل مختصين',
      valuation_d:'احصل على التقييم من افضل الخبراء',
      contact_phone:'رقم التواصل',
      EngineeringArticles:'مقالات هندسية',
      'Message sent successfully!':'الارسال تم بنجاح',
      text:'النص',
      'Select Source Language':'اللغة المصدر',
      'Select Target Language':'اللغة الهدف',
      translate:'ترجمة',
      "Overview": "نظرة عامة",
      "An article at Eye Engineer is a piece of writing on a specific engineering topic. It is required to adhere to the following points:": "المقال في عين المهندس هو قطعة كتابة حول موضوع هندسي محدد. يتطلب الالتزام بالنقاط التالية:",
      "The article must be exclusively focused on engineering topics (no unrelated articles are allowed).": "يجب أن يكون المقال مركزًا بشكل حصري على المواضيع الهندسية (لا يسمح بالمقالات غير ذات الصلة).",
      "Maintain a formal writing style and avoid informal conversational language.": "الحفاظ على أسلوب كتابة رسمي وتجنب اللغة الحواراتية غير الرسمية.",
      "Keep the article simple and understandable, with an emphasis on explaining engineering terms.": "اجعل المقال بسيطًا ومفهومًا مع التركيز على شرح المصطلحات الهندسية.",
      "The article should be aimed at scientific benefit based on reliable engineering facts.": "يجب أن يهدف المقال إلى الفائدة العلمية بناءً على حقائق هندسية موثوقة.",
      "Provide names of used references, their publication date, the publishing house's name, and page numbers.": "تقديم أسماء المراجع المستخدمة، تاريخ النشر، اسم دار النشر، وأرقام الصفحات.",
      "Also, mention the names of the websites quoted, along with the date of quoting.": "اذكر أيضًا أسماء المواقع الإلكترونية المقتبسة، مع تاريخ الاقتباس.",
      "The author of the article is responsible for the accuracy of this information both scientifically and legally.": "يكون مؤلف المقال مسؤولاً عن دقة هذه المعلومات من الناحية العلمية والقانونية.",
      "The article should have a well-structured format in terms of font, sentence structure, and overall readability.": "يجب أن يكون للمقال تنسيق جيد من حيث الخط، بنية الجمل، وسهولة القراءة العامة.",
      "Choose an appropriate title that reflects the content of the article.": "اختر عنوانًا مناسبًا يعكس محتوى المقال.",
      "Avoid including any article containing unwanted emails or harmful programs.": "تجنب إدراج أي مقال يحتوي على رسائل بريد إلكتروني غير مرغوب فيها أو برامج ضارة.",
      "Ensure compliance with all copyright and other relevant laws.": "التأكد من الامتثال لجميع قوانين حقوق النشر والقوانين ذات الصلة الأخرى.",
      "Respect copyright laws, as our policy involves responding to explicit notifications claiming copyright violations, leading to the immediate removal of the article.": "احترام قوانين حقوق النشر، حيث تتضمن سياستنا الاستجابة للإشعارات الصريحة التي تدعي انتهاكات حقوق النشر، مما يؤدي إلى إزالة المقال على الفور.",
      "Avoid publishing personal information that should not be generally accessible.": "تجنب نشر المعلومات الشخصية التي لا ينبغي أن تكون متاحة بشكل عام.",
      "Engineers who wish to contribute to Eye Engineer are requested to send their requests to the Eye Engineer team at info@eyeengineer.com.": "يطلب من المهندسين الذين يرغبون في المساهمة في عين المهندس إرسال طلباتهم إلى فريق عين المهندس على info@eyeengineer.com.",
      "QuestionsPolicy": "سياسة الأسئلة",
      "Question Conditions": "شروط السؤال",
      "The question must be scientific and engineering only (no response will be provided if the question is outside the scope of engineering).": "يجب أن يكون السؤال علميًا وهندسيًا فقط (لن يتم تقديم رد إذا كان السؤال خارج نطاق الهندسة).",
      "The question should be concise and not a text.": "يجب أن يكون السؤال مختصرًا وليس نصًا.",
      "Only one question at a time.": "سؤال واحد في كل مرة.",
      "Avoid personal details and refrain from using personal terms.": "تجنب التفاصيل الشخصية وامتنع عن استخدام المصطلحات الشخصية.",
      "Inappropriate words or any content unrelated to engineering are not allowed.": "لا يسمح بالكلمات غير المناسبة أو أي محتوى غير متعلق بالهندسة.",
      "To avoid redundant questions, it is required to search for the topic on the website before asking the question.": "لتجنب الأسئلة المكررة، من الضروري البحث عن الموضوع على الموقع قبل طرح السؤال.",
      "Answer Policy on Eye Engineer Website": "سياسة الإجابة على موقع عين المهندس",
      "Answers should be written in proper Arabic, clear and understandable to Arab readers; English can be used in the answer.": "يجب أن تكون الإجابات مكتوبة بالعربية الفصحى، واضحة ومفهومة للقراء العرب؛ يمكن استخدام الإنجليزية في الإجابة.",
      "The answer should be comprehensive and explanatory for each point in the question.": "يجب أن تكون الإجابة شاملة وشرحًا لكل نقطة في السؤال.",
      "The answer must be based on scientific engineering principles only and should not reflect personal opinions or be quoted from forums.": "يجب أن تستند الإجابة إلى المبادئ الهندسية العلمية فقط ولا تعكس الآراء الشخصية أو تقتبس من المنتديات.",
      "Avoid any form of advertising or promotion for a specific company.": "تجنب أي شكل من أشكال الإعلان أو الترويج لشركة معينة.",
      "Stay away from texts unrelated to the engineering field.": "تجنب النصوص غير المتعلقة بمجال الهندسة.",
      "Avoid any form of personal promotion during the answer.": "تجنب أي شكل من أشكال الترويج الشخصي أثناء الإجابة.",
      "Engineer's response is considered indirect promotion, and contact information can be placed in their profile, not in the answer.": "تعتبر استجابة المهندس ترويجًا غير مباشر، ويمكن وضع معلومات الاتصال في ملفه الشخصي، وليس في الإجابة.",
      "This should not encourage the questioner to contact or visit.": "يجب ألا تشجع السائل على الاتصال أو زيارة.",
      "Avoid mentioning the name of the questioner or any personal information; the Eye Engineer website respects the privacy of individuals.": "تجنب ذكر اسم السائل أو أي معلومات شخصية؛ موقع عين المهندس يحترم خصوصية الأفراد.",
      "Respect for Other Engineers Opinions": "احترام آراء المهندسين الآخرين",
      "Respond to the posed question and attempt to express one's point of view based on scientific engineering principles, avoiding personal biases or unrelated matters.": "استجب للسؤال المطروح وحاول التعبير عن وجهة نظرك استنادًا إلى المبادئ الهندسية العلمية، مع تجنب الانحيازات الشخصية أو المسائل غير ذات الصلة.",
      "Note: Any violation of these points will result in the immediate deletion of the answer without prior notification to the engineer.": "ملاحظة: أي انتهاك لهذه النقاط سيؤدي إلى حذف الإجابة على الفور دون إشعار مسبق للمهندس.",
      TerminologiesC:'اصناف',
      Classifications:'التصنيفات',
      Articles:'المقالات',
      Office_Reservation:'حجز مكتب',
      dr:'قم بحجز مساحة في موقعنا لعرض معلومات المكتب وملفاته. يوفر موقعنا الإلكتروني للعملاء إمكانية حجز مكان خاص لعرض تفاصيل المكتب والوثائق ذات الصلة. سواء كنت ترغب في عرض مواصفات المكتب، أو الصور، أو معلومات أخرى ذات الصلة، فإن نظام الحجز الخاص بنا يتيح لك حجز مساحة محددة لهذا الغرض. احجز مساحتك الآن لتقديم مكتبك ومواده المرتبطة بشكل فعال.',
      office_name:'اسم المكتب',
      office_description:'وصف المكتب',
      checkcode:'تحقق من بريدك الإلكتروني وأدخل الرمز',
      Accept:'تأكيد',
      Code:'الرمز',
      'code is required':'الرمز مطلوب ادخاله',
      'The code is wrong':'الرمز خطأ',
      profile:'الملف الشخصي',
      Dashboard:'لوحة التحكم',
      'Enter your email':'أدخل بريدك الإلكتروني',
      'The Email not found':'الحساب غير موجود',
      passwordUpdate:'تم تحديث كلمة المرور',
      golo:'اذهب لتسجيل الدخول مرة اخرى',
      Delete:'حذف',
      'Logged out successfully':'تم تسجيل الخروج',
      'Account deleted successfully':'تم حذف الحساب بنجاح',
      'Account successfully created':'تم انشاء الحساب بنجاح',
      'logged in successfully':'تم تسجيل الدخول بنجاح',
      'Profile modified successfully':'تم تعديل الملف الشخصي بنجاح',
      'Edit Profile':'تعديل الملف الشخصي',
      Edit:'تعديل',
      VIDEOS:'فيديوهات',
      AUDIOS:'صوتيات',
      IMAGES:'صور',
      PDF:'ملفات محمولة',
      ZIP:'ملفات مضغوطة',
      'Videos are not found':'لايوجد فيديوهات',
      'Recents are not found':'لايوجد بيانات',
      'Geometries are not found':'لايوجد هندسات',
      'Offices are not found':'المكاتب غير موجودة',
      'Terminologies are not found':'لايوجد مصطلحات',
      'Categories are not found':'لايوجد اصناف',
      'Classifications are not found':'لايوجد تصنيفات',
      'Links are not found':'لايوجد روابط',
      'Articles are not found':'لايوجد مقالات',
      'Reserve':'احجز',
      'The office is blocked':'المكتب محظور',
      files:'ملفات',
      links:'روابط',
      articles:'مقالات',
      Users:'المستخدمين',
      Slides:'شرائح',
      News:'اخبار',
      Reservations:'حجوزات',
      Messages:'رسائل',
      "My Offices":'مكاتبي',
      Forbidden:'محظور',
      search:'بحث',
      Name:'اسم',
      Image:'صورة',
      Phone:'موبايل',
      Experiences:'خبرات',
      Title:'عنوان',
      Url:'رابط',
      Text:'نص',
      Type:'نوع',
      'Add file':'اضافة ملف',
      'Update file':'تعديل ملف',
      'Save':'حفظ',
      'Upload file':'تحميل ملف',
      Role:'رتبة',
      'Upload Image':'تحميل صورة',
      'Add User':'اضافة مستخدم',
      Admin:'ادمن',
      User:'مستخدم',
      'Choose A Role':'اختر رتبة',
      'Update User':'تعديل المستخدم',
      'Add Slider':'اضافة شريحة',
      'Update Slider':'تعديل شريحة',
      New:'الخبر',
      'Add New':'اضافة خبر',
      'Update New':'تعديل خبر',
      Service:'الخدمة',
      'show able':'امكانية العرض',
      Subject:'الموضوع',
      Content:'المحتوى',
      Description:'الوصف',
      Priority:'اولوية',
      'Add Geometry':'اضافة هندسة',
      'Update Geometry':'تعديل هندسة',
      Geometry:'هندسة',
      'Add Classification':'اضافة صنف',
      'Update Classification':'تعديل صنف',
      'Video':'فيديو',
      'Upload Video':'تحميل فيديو',
      'Add Video':'اضافة فيديو',
      'Update Video':'تعديل فيديو',
      'Add Recent':'اضافة',
      'Update Recent ':'تعديل',
      Meaning:'معنى',
      Field:'حقل',
      'Terminologies Categories':'اصناف المصطلحات',
      Terminologie:'المصطلح',
      'Add Terminology':'اضافة مصطلح',
      'Add Category':'اضافة صنف',
      'Update Terminology':'تعديل مصطلح',
      'Update Category':'تعديل صنف',
      'Update Office':'تعديل مكتب',
      'Add Office':'اضافة مكتب',
      Add:'اضافة',
      Remove:'حذف',
      'Enter service':'ادخل خدمة',
      'Enter experience':'ادخل خبرة',
      'Update Link':'تعديل رابط',
       'Add Link':'اضافة رابط',
       'Update Article':'تعديل مقالة',
       'Add Article':'اضافة مقالة',


      
    },
  },
};

const savedLanguage = localStorage.getItem("language") || "en";

i18n.use(initReactI18next).init({
  resources,
  lng: savedLanguage,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
