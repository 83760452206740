import { useTranslation } from "react-i18next";
import Content from "../../Components/Website/Content/Content";

export default function Capabilities(){
    const { t } = useTranslation();
    return(
        <>
            <h1 className="text-center" style={{color:"white",padding:'30px',backgroundColor:'#ED7200'}}> {t('OurCapabilities')}</h1>
            <Content/>
        </>
    )
}