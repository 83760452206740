import React from "react";
import { useTranslation } from "react-i18next";

export default function ArticlesPolicy() {
  const { t } = useTranslation();

  return (
    <>
      <h1
        className="text-center"
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        {t("Articles Policy")}
      </h1>
      <div className="container mt-4">
        <div className="mb-4">
          <h4 className="text-orange">{t("Overview")}:</h4>
          <p>{t("An article at Eye Engineer is a piece of writing on a specific engineering topic. It is required to adhere to the following points:")}</p>
          <p>{t("The article must be exclusively focused on engineering topics (no unrelated articles are allowed).")}</p>
          <p>{t("Maintain a formal writing style and avoid informal conversational language.")}</p>
          <p>{t("Keep the article simple and understandable, with an emphasis on explaining engineering terms.")}</p>
          <p>{t("The article should be aimed at scientific benefit based on reliable engineering facts.")}</p>
          <p>{t("Provide names of used references, their publication date, the publishing house's name, and page numbers.")}</p>
          <p>{t("Also, mention the names of the websites quoted, along with the date of quoting.")}</p>
          <p>{t("The author of the article is responsible for the accuracy of this information both scientifically and legally.")}</p>
          <p>{t("The article should have a well-structured format in terms of font, sentence structure, and overall readability.")}</p>
          <p>{t("Choose an appropriate title that reflects the content of the article.")}</p>
          <p>{t("Avoid including any article containing unwanted emails or harmful programs.")}</p>
          <p>{t("Ensure compliance with all copyright and other relevant laws.")}</p>
          <p>{t("Respect copyright laws, as our policy involves responding to explicit notifications claiming copyright violations, leading to the immediate removal of the article.")}</p>
          <p>{t("Avoid publishing personal information that should not be generally accessible.")}</p>
          <p>{t("Engineers who wish to contribute to Eye Engineer are requested to send their requests to the Eye Engineer team at info@eyeengineer.com.")}</p>
        </div>
      </div>
    </>
  );
}
