import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./PolicyComponent.css";

const ArabicPolicy = () => {
  return (
    <>
      <h1
        className="text-center"
        style={{ color: "white", padding: "30px", backgroundColor: "#ED7200" }}
      >
        سياسة الخصوصية
      </h1>
      <div className="container mt-4">
        <div className="mb-4">
          <h4 className="text-orange">مقدمة:</h4>
          <p>
            يعترف موقع Eye Engineer بأهمية حماية معلوماتك الشخصية.
          </p>
          <p>
            لقد أعددنا سياسة الخصوصية هذه لتزويدك بمعلومات هامة حول الممارسات المتعلقة بالخصوصية التي يتم تطبيقها على منصة Eye Engineer وأي موقع أو خدمة متصلة أو المشار إليها من خلال سياسة الخصوصية هذه (المشار إليها بشكل جماعي بـ "الخدمات").
          </p>
          <p>
            يعمل Eye Engineer على مساعدتك في العثور على معلومات موثوقة ومتعلقة بالهندسة وربطك بأفضل المهندسين لمشاركة المعلومات.
          </p>
          <p>
            يعمل Eye Engineer على ربطك بالمهندسين في أسرع وقت ممكن عندما تحتاج إلى معلومات (بالتنسيق المناسب للاستشارات الافتراضية) مباشرة من جهاز الكمبيوتر أو الهاتف المحمول الخاص بك.
          </p>
          <p>
            من خلال الاستمرار في استخدام موقع Eye Engineer، فإنك توافق على سياسة الخصوصية هذه وأي تحديثات يتم إجراؤها عليها.
          </p>
          <p>
            هذا يعني أنه إذا تم إجراء تغييرات على سياسة الخصوصية، فإنك توافق على تلك التغييرات من خلال الاستمرار في استخدام Eye Engineer.
          </p>
          <p>
            عندما يتم إجراء تغييرات على هذه السياسة (بخلاف التغييرات التي يتم إجراؤها لتصحيح الأخطاء المطبعية أو التغييرات الطفيفة التي لا تغير معناها)، سنقوم بتحديث تاريخ "آخر تعديل" في أعلى السياسة.
          </p>
          <p>
            في الجزء السفلي من السياسة، قد نقدم ملخصاً يصف أنواع التغييرات التي تم إجراؤها.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">الشروط والأحكام لاستخدام الموقع:</h4>
          <p>
            باستخدام الموقع، فإنك توافق على عدم استخدام أي جهاز أو برنامج أو روتين للتدخل أو محاولة التدخل في التشغيل الصحيح للموقع أو أي عملية تجارية تتم على الموقع، أو مع أي استخدام للموقع من قبل شخص آخر.
          </p>
          <p>
            لا يُسمح لك بالتلاعب بالعناوين أو المعرفات لإخفاء مصدر أي رسالة.
          </p>
          <p>
            يحظر تماماً انتحال أي فرد أو كيان.
          </p>
          <p>
            لا يجوز لك استخدام الموقع أو أي محتوى لأي غرض غير قانوني أو محظور بموجب شروط الاستخدام هذه.
          </p>
          <p>
            تقع على عاتقك مسؤولية الحفاظ على أمان حسابك وكلمات المرور الخاصة بك.
          </p>
          <p>
            أي خرق لحسابك قد يؤدي إلى مشاكل لمستخدم آخر أو قد يؤثر على موقعنا والخدمات الخاصة بنا.
          </p>
          <p>
            لذلك، فأنت مسؤول تماماً عن أمان حسابك على الموقع.
          </p>
          <p>
            يجب أن تكون على علم أن أي رسائل أو معاملات تقوم بها قد تكون مرئية للآخرين بقصد الاستغلال.
          </p>
          <p>
            لا يمكن للموقع ضمان أن أي ملفات أو بيانات تقوم بتنزيلها من الموقع ستكون خالية من الفيروسات أو الملوثات أو الميزات الداعمة.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">جمع المعلومات:</h4>
          <p>
            عند استخدامك لخدماتنا، قد نقوم بجمع الأنواع التالية من المعلومات:
          </p>

          <h5>المعلومات التي تقدمها مباشرة:</h5>
          <p>
            لبعض الأنشطة، مثل عندما تقوم بالتسجيل، أو الاشتراك في إشعاراتنا، أو التواصل معنا مباشرة؛ قد نقوم بجمع بعض أو كل الأنواع التالية من المعلومات:
          </p>
          <p>
            <strong>التسجيل:</strong> معلومات الاتصال، مثل اسمك، عنوان بريدك الإلكتروني، تاريخ ميلادك، ورقم هاتفك المحمول.
          </p>
          <p>
            <strong>معلومات الدفع:</strong> عند استخدام الخدمات المدفوعة على Eye Engineer، نطلب معلومات الدفع.
          </p>
          <p>
            إذا قمت بالدفع باستخدام بطاقة ائتمان أو بطاقة خصم محلية، سنقوم بجمع معلومات محددة مثل رقم بطاقة الائتمان أو بطاقة الخصم، تاريخ انتهاء الصلاحية، ورمز الأمان.
          </p>
          <p>
            بالإضافة إلى ذلك، ستجمع خدمة الدفع (طرف ثالث يساعدنا أو يمثلنا في تسهيل خدماتنا) معلومات من بطاقة الائتمان أو بطاقة الخصم الخاصة بك.
          </p>
          <p>
            استخدامهم لهذه المعلومات سيكون خاضعاً لسياسة الخصوصية الخاصة بهم.
          </p>
          <p>
            <strong>مقدمو المعلومات الهندسية:</strong> سنقوم بجمع معلومات محددة حول عملك، مثل المسمى الوظيفي، مجال الممارسة، التخصص الأساسي، الجنس، تاريخ الميلاد، اللغات المنطوقة، العنوان، الصورة، معلومات الترخيص المهني، ومعلومات حسابك البنكي.
          </p>
          <p>
            <strong>معلومات أخرى:</strong> بالإضافة إلى ذلك، سنقوم بجمع أي معلومات أخرى تقدمها لنا.
          </p>
          <p>
            من المهم أن نلاحظ أن أي معلومات دفع يتم جمعها يتم معالجتها وفقًا لسياسة الخصوصية لمزود خدمة الدفع، وهو طرف ثالث يشارك في معالجة عمليات الدفع الخاصة بنا.
          </p>
          <h5>المعلومات التي يتم جمعها تلقائيًا:</h5>
          <p>
            عند استخدامك لخدماتنا، قد نقوم بجمع تلقائي لأنواع معينة من المعلومات، بما في ذلك ولكن لا تقتصر على:
          </p>
          <p>
            <strong>عنوان IP ومعرفات الجهاز:</strong> معلومات مثل عنوان IP، معرفات الجهاز، معرّفات الإعلانات، نوع المتصفح، نظام التشغيل، مزود خدمة الإنترنت، الصفحات التي تمت زيارتها قبل وبعد استخدام الخدمات، تاريخ ووقت الزيارة، ومعلومات سجلات الخادم القياسية.
          </p>
          <p>
            <strong>ملفات تعريف الارتباط، علامات البكسل، والكائنات المشتركة المحلية:</strong> قد نستخدم ملفات تعريف الارتباط، علامات البكسل، والكائنات المشتركة المحلية (المعروفة أيضاً بـ "ملفات تعريف الارتباط الفلاش") لجمع المعلومات تلقائيًا.
          </p>
          <p>
            ملفات تعريف الارتباط هي قطع صغيرة من المعلومات المخزنة بواسطة متصفح الويب الخاص بك على جهاز الكمبيوتر الخاص بك.
          </p>
          <p>
            علامات البكسل هي صور صغيرة أو أجزاء صغيرة من البيانات المدمجة في الصور، والمعروفة أيضاً بـ "المؤشرات الشبكية" أو "صور GIF الشفافة"، والتي يمكن أن تتعرف على ملفات تعريف الارتباط ووقت وتاريخ مشاهدة الصفحة، بالإضافة إلى وصف الصفحة التي تم وضع علامة البكسل فيها.
          </p>
          <p>
            الكائنات المشتركة المحلية مشابهة لملفات تعريف الارتباط القياسية ولكن قد تكون أكبر حجمًا ويتم تنزيلها إلى جهاز الكمبيوتر أو الجهاز المحمول الخاص بك بواسطة مشغل Adobe Flash.
          </p>
          <p>
            باستخدام خدماتنا، فإنك توافق على استخدامنا لملفات تعريف الارتباط، علامات البكسل، الكائنات المشتركة المحلية، والتقنيات المشابهة.
          </p>
          <p>
            <strong>الكائنات المشتركة المحلية (ملفات تعريف الارتباط الفلاش):</strong> الكائنات المشتركة المحلية، والتي تعرف أحياناً بـ "ملفات تعريف الارتباط الفلاش"، تشبه ملفات تعريف الارتباط القياسية ولكن قد تكون أكبر حجمًا ويتم تنزيلها إلى جهاز الكمبيوتر أو الجهاز المحمول الخاص بك بواسطة مشغل Adobe Flash.
          </p>
          <p>
            باستخدام خدماتنا، فإنك توافق على استخدامنا لملفات تعريف الارتباط، علامات البكسل، الكائنات المشتركة المحلية، والتقنيات المشابهة.
          </p>
          <p>
            <strong>البيانات التقنية لحل المشكلات:</strong> قد نقوم أيضًا بجمع بيانات تقنية لمعالجة وحل المشكلات التقنية وتحسين خدماتنا.
          </p>
          <p>
            قد تشمل هذه البيانات معلومات عن حالة ذاكرة جهازك في حال حدوث فشل في النظام أو التطبيق أثناء استخدام خدماتنا.
          </p>
          <p>
            قد تسمح إعدادات جهازك أو متصفحك بالتحكم في جمع هذه البيانات التقنية.
          </p>
          <p>
            قد تتضمن هذه البيانات أجزاء من مستند كنت تستخدمه عند حدوث مشكلة أو محتويات اتصالاتك.
          </p>
          <p>
            باستخدام الخدمات، فإنك توافق على جمع هذه البيانات التقنية.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">كيف نستخدم المعلومات:</h4>
          <p>
            نستخدم المعلومات التي نجمعها للأغراض التالية:
          </p>
          <p>
            <strong>لتوفير وتحسين خدماتنا:</strong> لتقديم الخدمات التي طلبتها، ولتحسين تجربتك، ولإدارة وتخصيص تفاعلاتك مع خدماتنا.
          </p>
          <p>
            <strong>للتحليل:</strong> لتحليل كيفية استخدامك للخدمات وتقديم محتوى أو ميزات قد تهمك.
          </p>
          <p>
            <strong>للإعلانات:</strong> قد نستخدم المعلومات لتقديم إعلانات تتناسب مع اهتماماتك.
          </p>
          <p>
            <strong>للأمان:</strong> لحماية أمان خدماتنا ومنع أي انتهاكات لسياسة الخصوصية.
          </p>
          <p>
            <strong>للإشعارات:</strong> لإعلامك بتحديثات حول خدماتنا أو تغييرات في سياسة الخصوصية.
          </p>
          <p>
            <strong>للتواصل:</strong> للتواصل معك بشأن الأسئلة أو الاستفسارات أو الشكاوى.
          </p>
          <p>
            <strong>لتحليل الأداء:</strong> لتحليل أداء خدماتنا وتحسينها.
          </p>
          <p>
            <strong>لإجراء الأبحاث:</strong> لإجراء الأبحاث اللازمة لتطوير خدماتنا واستكشاف الحلول الجديدة.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">كيف نشارك المعلومات:</h4>
          <p>
            قد نشارك معلوماتك مع أطراف ثالثة فقط في الحالات التالية:
          </p>
          <p>
            <strong>مقدمو الخدمات:</strong> قد نشارك معلوماتك مع مزودي الخدمة الذين يساعدوننا في تقديم خدماتنا، مثل مقدمي خدمات الدفع أو دعم العملاء.
          </p>
          <p>
            <strong>الامتثال للقوانين:</strong> قد نكشف معلوماتك إذا كان ذلك مطلوبًا بموجب القانون أو بناءً على طلب قانوني.
          </p>
          <p>
            <strong>لحماية الحقوق:</strong> قد نكشف معلوماتك لحماية حقوقنا أو ممتلكاتنا أو سلامتنا وسلامة الآخرين.
          </p>
          <p>
            <strong>في حالة التغيير:</strong> إذا تم الاستحواذ على Eye Engineer أو دمجها في شركة أخرى، قد يتم نقل معلوماتك كجزء من العملية.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">حقوقك:</h4>
          <p>
            لديك الحق في الوصول إلى معلوماتك، وتحديثها، وتصحيحها. يمكنك الاتصال بنا لممارسة هذه الحقوق.
          </p>
          <p>
            يمكنك أيضاً طلب حذف معلوماتك الشخصية. ومع ذلك، قد نحتفظ ببعض المعلومات وفقًا لمتطلبات القوانين أو اللوائح.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">الأمن:</h4>
          <p>
            نستخدم تدابير أمان مناسبة لحماية معلوماتك الشخصية من الوصول غير المصرح به أو الاستخدام أو التعديل.
          </p>
          <p>
            ومع ذلك، لا يمكننا ضمان أمان المعلومات التي يتم إرسالها عبر الإنترنت أو تخزينها.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">التغييرات على سياسة الخصوصية:</h4>
          <p>
            قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنعلمك بأي تغييرات عن طريق نشر سياسة الخصوصية المحدثة على موقعنا.
          </p>
          <p>
            يشكل استمرارك في استخدام خدماتنا بعد إجراء أي تغييرات قبولك للسياسة المحدثة.
          </p>
        </div>
        <div className="mb-4">
          <h4 className="text-orange">اتصل بنا:</h4>
          <p>
            إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يمكنك الاتصال بنا على عنوان البريد الإلكتروني التالي: [بريدك الإلكتروني].
          </p>
        </div>
      </div>
    </>
  );
};

export default ArabicPolicy;
