import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./footer.css";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../../Api/Api";

export default function Footer({isChecked}) {
  const { t } = useTranslation();
  const [Services, setServices] = useState([]);
  const Mode = localStorage.getItem("mode");
  console.log(Mode)

  // Get All Services
  useEffect(() => {
    axios
      .get(`${baseUrl}/home`)
      .then((data) => {
        setServices(data.data.data.services);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={`footer  ${!isChecked? 'footer7' :'footer6' } pt-5 pb-5 text-white-50 text-center text-md-start`}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="info mb-5">
             {!isChecked? <img
                src={require("../../../Assets/images/logo1.png")}
                width="250px"
                height="100px"
                alt=""
                className="mb-4"
              />:
              
              <img
              src={require("../../../Assets/images/Logo_Eye Engineer_Olive.png")}
              width="250px"
              height="100px"
              alt=""
              className="mb-4"
            />
              
              
              }
              <p className={`mb-5 ${!isChecked? 'fpf7' :'fpf6' }`}  style={{ color: "#084B22" }}>
                {t("FooterDescription")}
              </p>
              {/* <div className="copyright" style={{ color: "#084B22" }}>
                {t("CreatedBy")} <span>Graphberry</span>
                <div>
                  {t("Copyright")} <span>Eyeengineer</span>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="links">
            <h5 className={`${!isChecked? 'h7' :'h6' } text-light`}>{t("Useful Links")}</h5>
              <ul className={`list-unstyled  ${!isChecked? 'list-unstyled7' :'list-unstyled6' } lh-lg`}>
                <NavLink to={"/home"}>
                  <li>{t("Home")}</li>
                </NavLink>
                <NavLink to={"/about_us"}>
                  <li>{t("About Us")}</li>
                </NavLink>
                <NavLink to={"/capabilities"}>
                  <li>{t("Capabilities")}</li>
                </NavLink>
                <NavLink to={"/contact_us"}>
                  <li>{t("Contact Us")}</li>
                </NavLink>
                {Services[5] && Services[5].show_able === 1 ? (
                  <NavLink to={"/offices"}>
                    <li>{t("Reserve Office")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[4] && Services[4].show_able === 1 ? (
                  <NavLink to={"/terminologies"}>
                    <li>{t("Intelligent Translator")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                <NavLink to={"/privacy-policy"}>
                  <li>{t("Privacy Policy")}</li>
                </NavLink>
                <NavLink to={"/articles-policy"}>
                  <li>{t("Articles Policy")}</li>
                </NavLink>
                <NavLink to={"/questions-policy"}>
                  <li>{t("Questions Policy")}</li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="links">
              <h5 className={`${!isChecked? 'h7' :'h6' } text-light`}>{t("Our Services")}</h5>
              <ul className={`list-unstyled ${!isChecked? 'list-unstyled7' :'list-unstyled6' }  lh-lg`}>
                {Services[1] && Services[1].show_able === 1 ? (
                  <NavLink to={"/geometries"}>
                    {" "}
                    <li>{t("Geometries")}</li>{" "}
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[2] && Services[2].show_able === 1 ? (
                  <NavLink to={"/shows"}>
                    <li>{t("Educational Videos")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[3] && Services[3].show_able === 1 ? (
                  <NavLink to={"/recents"}>
                    <li>{t("Recents")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[7] && Services[7].show_able === 1 ? (
                  <NavLink to={"/engineering_articles"}>
                    <li>{t("Engineering Articles")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[6] && Services[6].show_able === 1 ? (
                  <NavLink to={"/valuation"}>
                    <li>{t("Property Valuation")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[5] && Services[5].show_able === 1 ? (
                  <NavLink to={"/offices"}>
                    <li>{t("Offices")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
                {Services[4] && Services[4].show_able === 1 ? (
                  <NavLink to={"/terminologies"}>
                    <li>{t("Terminologies")}</li>
                  </NavLink>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="contact">
            <h5 className={`${!isChecked? 'h7' :'h6' } text-light`}>{t("Contact Us")}</h5>
              <p className={`lh-lg  mt-3 mb-5 ${!isChecked? 'fpf7' :'fpf6' }`} style={{ color: "#084B22" }}>
                {t("ContactDescription")}
              </p>
              <a
                className="btn rounded-pill main-btn w-100"
                href="mailto:info@eyeengineer.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Email_text")}
              </a>
              <ul className="d-flex mt-5 list-unstyled iconscenter gap-3">
                <li>
                  <a
                    className="d-block text-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/eyeengineer?mibextid=ZbWKwL"
                  >
                    <i className={`fa-brands ${!isChecked? 'i7' :'i6' } fa-facebook`}></i>
                  </a>
                </li>
                <li>
                  <a
                    className="d-block text-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/eyeengineer/"
                  >
                    <i className={`fa-brands ${!isChecked? 'i7' :'i6' } fa-instagram`}></i>
                  </a>
                </li>
                <li>
                  <a
                    className="d-block text-light"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.tiktok.com/@eye_engineer?_t=8oZFJF5ApO5&_r=1"
                  >
                    <i className={`fa-brands ${!isChecked? 'i7' :'i6' } fa-tiktok`}></i>
                  </a>
                </li>
                <li>
                  <a
                    className="d-block text-light"
                    href="https://teams.microsoft.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={`fa-brands ${!isChecked? 'i7' :'i6' } fa-t`}></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Floating WhatsApp Icon */}
      {Services[0] && Services[0].show_able === 1 ? (
        <a
          href="https://wa.me/963984944832"
          target="_blank"
          rel="noopener noreferrer"
          className="whatsapp-floating-icon"
        >
          <i className="fa-brands fa-whatsapp"></i>
        </a>
      ) : (
        ""
      )}
    </div>
  );
}
