import { Outlet } from "react-router-dom";
import SideBar from "../../Components/Dashboard/SideBar";
import TopBar from "../../Components/Dashboard/TopBar";
import "./dashboard.css";
// import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
export default function Dashboard() {
  const { t } = useTranslation();

  // useEffect(() => {
  //   const li = localStorage.getItem("login");
  //   if (li === "true") {
  //     toast.success(t("logged in successfully"));

  //     setTimeout(() => {
  //       localStorage.removeItem("login");
  //     }, 100);
  //   }
  // }, []);
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="position-relative dashboard">
        <TopBar />
        <div className="d-flex gap-1" style={{ marginTop: "70px" }}>
          <SideBar />
          <Outlet />
        </div>
      </div>
    </>
  );
}
